.events-registration-page .container {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}
.events-registration-page .container .event-and-registrations {
  display: flex;
  align-items: baseline;
  gap: 2rem;
}
.events-registration-page .container .event-and-registrations .event {
  background-color: whitesmoke;
  max-width: 300px;
  padding: 2rem;
  border-radius: 1rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.events-registration-page .container .event-and-registrations .registrations {
  flex: 1;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}
.events-registration-page .container .event-and-registrations .registrations .count {
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  background-color: #e6d3f7;
}/*# sourceMappingURL=eventRegistrations.css.map */