$primary-color: #9B51E0;

.events-registration-page {
    .container {
        display: grid;
        height: fit-content;
        gap: 2rem;

        .event-and-registrations {
            display: flex;
            align-items: baseline;
            gap: 2rem;

            .event {
                background-color: whitesmoke;
                max-width: 300px;
                padding: 2rem;
                border-radius: 1rem;
                display: grid;
                height: fit-content;
                gap: 1rem;

            }

            .registrations {
                flex: 1;
                display: grid;
                height: fit-content;
                gap: 1rem;

                .count {
                    padding: 0.2rem 0.5rem;
                    border-radius: 0.5rem;
                    background-color: lighten($color: $primary-color, $amount: 30);
                }
            }
        }
    }
}