$primary-color: #9B51E0;

.page-header {
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        .branding {
            background-color: transparent;
            border: none;
            padding: 0;
        }

        .menu-items {
            display: flex;
            align-items: center;
            gap: 1rem;

            .item {
                display: flex;
                align-items: center;
                gap: 0.2rem;
            }
        }

        .profile {
            display: flex;
            align-items: center;
            gap: 1rem;
            color: $primary-color;

            .country {
                background-color: #F9F9F9;
                padding: 0.5rem 1rem;
                border: 1px solid $primary-color;
                border-radius: 1rem;
            }

            .profile-pic {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3rem;
                height: 3rem;
                background-color: lighten($color: $primary-color, $amount: 30);
                border-radius: 50%;
            }
        }
    }
}