.auth-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-page .form {
  width: 500px;
}
.auth-page form {
  width: 100%;
}
.auth-page form button {
  width: 100%;
}

.form {
  display: grid;
  gap: 1rem;
  height: -moz-fit-content;
  height: fit-content;
}/*# sourceMappingURL=authPages.css.map */