.dashboard-page .container {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}
.dashboard-page .container .events-programs {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 1rem;
}
.dashboard-page .container .events-programs .events,
.dashboard-page .container .events-programs .programs {
  flex: 1 1 400px;
}
.dashboard-page .container .events-programs .events-list {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}
.dashboard-page .container .events-programs .events-list .event {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.dashboard-page .container .events-programs .events-list .event .img {
  width: 142px;
  height: 82px;
  background-color: white;
  border-radius: 5px;
}
.dashboard-page .container .events-programs .events-list .event .text {
  flex: 1;
  display: grid;
  gap: 0.5rem;
  height: -moz-fit-content;
  height: fit-content;
}
.dashboard-page .container .events-registrations {
  display: grid;
  gap: 1rem;
  height: -moz-fit-content;
  height: fit-content;
}
.dashboard-page .recent-registration {
  padding: 1rem;
  background-color: whitesmoke;
  border-radius: 1rem;
}/*# sourceMappingURL=dashboard.css.map */