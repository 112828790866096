.dashboard-page {
    .container {
        display: grid;
        height: fit-content;
        gap: 2rem;

        .events-programs {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            gap: 1rem;

            .events,
            .programs {
                flex: 1 1 400px;
            }

            .events-list {
                display: grid;
                height: fit-content;
                gap: 2rem;

                .event {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    .img {
                        width: 142px;
                        height: 82px;
                        background-color: white;
                        border-radius: 5px;
                    }

                    .text {
                        flex: 1;
                        display: grid;
                        gap: 0.5rem;
                        height: fit-content;
                    }
                }
            }
        }

        .events-registrations {
            display: grid;
            gap: 1rem;
            height: fit-content;
        }
    }

    .recent-registration {
        padding: 1rem;
        background-color: whitesmoke;
        border-radius: 1rem;
    }
}