@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
* {
  margin: 0;
  padding: 0;
}

.body {
  font-family: "Quicksand", sans-serif;
  font-size: 1rem;
}

.container {
  width: 90vw;
  margin: 0 auto;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-link {
  color: #9B51E0;
  text-decoration: underline;
}

.card {
  background-color: #F3F3F3;
  padding: 2rem;
  border-radius: 1rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1rem;
}

small {
  color: gray;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table td,
table th {
  padding: 1rem;
  text-align: left;
}
table tr:nth-child(even) {
  background-color: #F8F8F8;
}
table thead {
  background-color: #cda8ef;
}

.branding {
  background-color: #e6d3f7;
  padding: 1rem 2rem;
  border: 2px solid #cda8ef;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.branding .logo {
  max-height: 42px;
  width: auto;
}

.title {
  color: #9B51E0;
}

input,
select,
button {
  padding: 1rem 1.2rem;
  background-color: #F3F3F3;
  border: 1px solid #F3F3F3;
  outline: none;
  border-radius: 1rem;
  font-size: inherit;
}
input::-moz-placeholder, select::-moz-placeholder, button::-moz-placeholder {
  color: gray;
  font-size: inherit;
}
input::placeholder,
select::placeholder,
button::placeholder {
  color: gray;
  font-size: inherit;
}

form,
.form {
  display: grid;
  gap: 1rem;
  height: -moz-fit-content;
  height: fit-content;
}

.primary-button {
  background-color: #9B51E0;
  color: white;
}

.full-button {
  width: 100%;
}/*# sourceMappingURL=main.css.map */