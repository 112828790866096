.page-header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.page-header .container .branding {
  background-color: transparent;
  border: none;
  padding: 0;
}
.page-header .container .menu-items {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.page-header .container .menu-items .item {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.page-header .container .profile {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #9B51E0;
}
.page-header .container .profile .country {
  background-color: #F9F9F9;
  padding: 0.5rem 1rem;
  border: 1px solid #9B51E0;
  border-radius: 1rem;
}
.page-header .container .profile .profile-pic {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background-color: #e6d3f7;
  border-radius: 50%;
}/*# sourceMappingURL=header.css.map */