$primary-color: #9B51E0;

.auth-page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .form {
        width: 500px;
    }

    form {
        width: 100%;

        button {
            width: 100%;
        }
    }
}

.form {
    display: grid;
    gap: 1rem;
    height: fit-content;
}